import React from 'react';
import {Link} from 'react-router-dom';

//Images
import Icon9 from './../../assets/images/icons/icon9.svg';
import Icon10 from './../../assets/images/icons/icon10.svg';
import Icon11 from './../../assets/images/icons/icon11.svg';
import Icon12 from './../../assets/images/icons/icon12.svg';
import Icon13 from './../../assets/images/icons/icon13.svg';
import pic1 from './../../assets/images/about/pic1.jpg';
import support1 from './../../assets/images/icons/support1.png';

const cardData = [
    {image: Icon9, title:'Best prices', detail: 'G63 collaborates directly with many manufacturers and suppliers to deliver products straight to consumers. This eliminates intermediary costs, helping consumers save 20% to 80% compared to traditional purchasing methods'},
    {image: Icon10, title:'One-on-one direct guidance', detail: 'G63 always has staff, collaborators, and members present every day at the sales areas where G63 operates, ready to provide direct support to each customer individually'},
    {image: Icon11, title:'Essential products', detail: 'G63 operates with the strategy of selling what people need. Therefore, G63 partners with suitable collaborators in each area to fully meet the daily and frequent consumer needs'},
    {image: Icon12, title:'High-quality products', detail: 'G63 carefully selects input partners to ensure that products sold through G63 are always of high quality'},
    {image: Icon13, title:'Cheap shipping fees', detail: `G63's development strategy focuses on expanding in high-potential areas to optimize operational costs, allowing goods sold through G63 to have free shipping or shipping fees of only 5k or 10k`},
];

function OneStop(){
    return(
        <>
            {cardData.map((item, ind)=>(
                <div className="col-xl-4 col-md-6 m-b60" key={ind}>
                    <div className="icon-bx-wraper style-3 text-center">
                        <div className="icon-media">
                            <img src={item.image} alt="" />
                        </div>
                        <div className="icon-content">
                            <h4 className="title">{item.title}</h4>
                            <p className="m-b0">{item.detail}</p>
                        </div>
                    </div>
                </div>
            ))}
            <div id='contact' className="col-xl-4 col-md-6 m-b60">
                <div className="icon-bx-wraper style-4" style={{backgroundImage: "url("+ pic1 + ")"}}>
                    <div className="inner-content">
                        <div className="icon-media m-b30">
                            <img src={support1} alt="" />
                        </div>
                        <div className="icon-content">
                            <Link to={"/contact-us"} className="btn btn-primary">+84 839 020 023</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OneStop;